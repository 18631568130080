import './style.css';
import * as THREE from 'three';
import * as TWEEN from '@tweenjs/tween.js';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { CSS2DObject } from 'three/examples/jsm/renderers/CSS2DRenderer';
import { TextGeometry } from 'three/examples/jsm/geometries/TextGeometry';
import { PointerLockControls } from 'three/examples/jsm/controls/PointerLockControls';
import * as lilGui from 'lil-gui';
import gsap from 'gsap';
const textureLoader = new THREE.TextureLoader();


// Canvas
const raycaster = new THREE.Raycaster();
const mouse = new THREE.Vector2();
const canvas = document.querySelector('canvas');

// Scene
const scene = new THREE.Scene();



// Camera
const camera = new THREE.PerspectiveCamera(
  45, // Field of View
  window.innerWidth / window.innerHeight, // Aspect Ratio
  0.1, // Near
  1000 // Far
);

// Initial position of the camera
// camera.position.set(-4.9, 4.4, 1.9);
// camera.rotation.set(-0.9, -0.8, -0.8);

// Renderer
const renderer = new THREE.WebGLRenderer({
  canvas: canvas,
});
renderer.setSize(window.innerWidth, window.innerHeight);
renderer.shadowMap.enabled = true;
renderer.shadowMap.type = THREE.PCFSoftShadowMap;
// Orbit Controls
// const controls = new OrbitControls(camera, canvas);
// controls.enableDamping = true;
// controls.enableZoom = true;
// controls.enableRotate   = true;
// controls.enablePan    = false;

const controls = new PointerLockControls(camera, document.body);
scene.add(controls.getObject());


let position = 0;

// gltf Loader

const audioContext = new (window.AudioContext || window.webkitAudioContext)();
const audioElement = new Audio('/audio/test-audio.mp3');
const audioSource = audioContext.createMediaElementSource(audioElement);
const audioGainNode = audioContext.createGain();

audioSource.connect(audioGainNode);
audioGainNode.connect(audioContext.destination);

const gltfLoader = new GLTFLoader();
let model;
let wallsBoundingBox
gltfLoader.load('/model/swedish-royal/scene.gltf', (gltf) => {
  console.log('Our model here!', gltf);
  gltf.scene.traverse((child) => {

    if (child.isMesh && child.material) {

      child.castShadow = true;
      child.receiveShadow = true;

      if (child.material) {
        child.material.side = THREE.DoubleSide; // Optional: Ensure both sides of the material cast shadows
        child.material.shadowSide = THREE.DoubleSide; // Optional: Ensure both sides of the material receive shadows
      }

      console.log(child.material)
      // Check if the material has a map (texture)
      if (child.material.map) {

        // if (child.material.name == 'Wall'){
        //   textureLoader.load('/model/vr_set/textures/Wall_baseColor.png', (texture) => {
        //
        //     // Assign the new texture to the material
        //     child.material.map = texture;
        //     // Ensure the texture is properly mapped onto the geometry (optional)
        //     child.material.map.wrapS = THREE.RepeatWrapping;
        //     child.material.map.wrapT = THREE.RepeatWrapping;
        //     child.material.map.repeat.set(1, 1);
        //     // Update the material to reflect the changes
        //     child.material.needsUpdate = true;
        //   });
        // }


      }

      // Check if the material has a color
      // if (child.material.color) {
      //   const colorValue = child.material.color.getHex();
      //   console.log(`Mesh color: #${colorValue.toString(16)}`);
      // }
    }
  });
  model = gltf.scene;
  scene.add(model);
  // const ambientLight = new THREE.AmbientLight(0xffffff, 1); // Adjust intensity as needed
  // scene.add(ambientLight);

  // wallsBoundingBox = new THREE.Box3().setFromObject(model);
});

document.addEventListener('click', () => {
  controls.lock();
});
// document.getElementById('playButton').addEventListener('click', () => {
//   audioElement.play();
// });


// const element = document.createElement('div');
// element.id = 'myElement';
// element.textContent = 'HTML Element inside Three.js';
//
// // Create CSS3DObject
// const cssObject = new CSS2DObject(element);
// cssObject.position.set(1.6,  0.89, -1.73);
//
// // Add CSS3DObject to the scene
// scene.add(cssObject);

// Animation and loop


// controls.addEventListener('change', () => {
//   // Check if controls is properly defined
//   if (controls && controls.target) {
//     // Check if the camera's target position is outside the walls bounding box
//     if (!wallsBoundingBox.containsPoint(controls.target)) {
//       // If outside, move the target back inside the bounding box
//       const correctedTarget = wallsBoundingBox.clampPoint(controls.target.clone());
//       controls.target.copy(correctedTarget);
//     }
//   }
// });
//
document.addEventListener('keydown', onKeyDown, false);

document.addEventListener('keydown', onKeyDown, false);
document.addEventListener('keyup', onKeyUp, false);

let moveForward = false;
let moveBackward = false;
let moveLeft = false;
let moveRight = false;

function onKeyDown(event) {
  switch (event.code) {
    case 'KeyW':
      moveForward = true;
      break;
    case 'KeyA':
      moveLeft = true;
      break;
    case 'KeyS':
      moveBackward = true;
      break;
    case 'KeyD':
      moveRight = true;
      break;
  }
}

function onKeyUp(event) {
  switch (event.code) {
    case 'KeyW':
      moveForward = false;
      break;
    case 'KeyA':
      moveLeft = false;
      break;
    case 'KeyS':
      moveBackward = false;
      break;
    case 'KeyD':
      moveRight = false;
      break;
  }
}

//-4.455365537867798, y: 4.097790281556696, z: 1.8995263156295836



canvas.addEventListener('dblclick', onDoubleClick, false);

function onDoubleClick(event) {
  event.preventDefault();

  // Get the mouse coordinates in normalized device coordinates
  const mouseX = (event.clientX / window.innerWidth) * 2 - 1;
  const mouseY = -(event.clientY / window.innerHeight) * 2 + 1;

  // Raycast to find the intersection point
  raycaster.setFromCamera({ x: mouseX, y: mouseY }, camera);
  const intersects = raycaster.intersectObjects(scene.children, true);

  if (intersects.length > 0) {
    // Get the intersection point
    const intersectionPoint = intersects[0].point;

    // Specify the desired distance from the subject
    const distanceFromSubject = 5; // Adjust this value based on your preference

    // Calculate the direction vector from the subject
    const directionVector = intersectionPoint.clone().sub(camera.position).normalize();

    // Calculate the new camera position
    const newPosition = intersectionPoint.clone().add(directionVector.multiplyScalar(-distanceFromSubject));

    // logAndStoreCameraProperties()
    // Animate the camera to the new position smoothly
    gsap.to(camera.position, { x: newPosition.x, y: newPosition.y, z: newPosition.z, duration: 3, ease: 'power2.out' });

    // Set the new target position smoothly
    gsap.to(controls.target, { x: intersectionPoint.x, y: intersectionPoint.y, z: intersectionPoint.z, duration: 3, ease: 'power2.out' });

    // Zoom out smoothly
    gsap.to(controls, { zoom: 1, duration: 3, ease: 'power2.out' });
  }
}

// const cameraStates = [];
// function logAndStoreCameraProperties() {
//   const rotation = new THREE.Euler().copy(camera.rotation);
//   const position = new THREE.Vector3().copy(camera.position);
//
//   // console.log('Camera Rotation:', rotation);
//   // console.log('Camera Position:', position);
//
//   // Store the state in the array
//   cameraStates.push({ rotation, position });
//
//   console.log(cameraStates);
// }



const cameraStates = [
  { rotation: { _x: -1.1631648213372698, _y: -0.7964676074245662, _z: -1.0274159632176514, _order: 'XYZ' }, position: { x: -4.9, y: 4.4, z: 1.9 } },
  { rotation: { _x: -1.4833324253518216, _y: -0.9515055678488241, _z: -1.4635237710294795, _order: 'XYZ' }, position: { x: -4.545686, y: 4.148397, z: 1.8779379999999997 } },
  { rotation: { _x: -0.7905585207393192, _y: -1.1578625650544123, _z: -0.7466991668174934, _order: 'XYZ' }, position: { x: -0.3916259999999996, y: 2.854844, z: 0.5976670000000002 } },
  { rotation: { _x: 0.048745827470747856, _y: -1.1188057276582575, _z: 0.043857370006578515, _order: 'XYZ' }, position: { x: 0.728852, y: 2.889185, z: 0.050175 } },
  { rotation: { _x: 0.13290865369396668, _y: -0.8209681457594766, _z: 0.09752971573394015, _order: 'XYZ' }, position: { x: 0.482577, y: 2.857213, z: 0.284594 } },
  { rotation: { _x: 0.13636687053194319, _y: -0.5225065537250154, _z: 0.06837266982289836, _order: 'XYZ' }, position: { x: 0.759044, y: 2.928364, z: -0.232927 } },
  { rotation: { _x: 0.1544205273796055, _y: -0.2858127296008286, _z: 0.04385815349540943, _order: 'XYZ' }, position: { x: 0.928489, y: 3.02972, z: -0.874859 } },
  { rotation: { _x: 0.08283726966571522, _y: -0.010621729848048033, _z: 0.0008818763995513528, _order: 'XYZ' }, position: { x: 0.931997, y: 3.019256, z: -0.732904 } },
  { rotation: { _x: -0.2652397134005352, _y: -0.06523472565110665, _z: -0.01770594070461059, _order: 'XYZ' }, position: { x: 0.9805996542819757, y: 2.8243181689368075, z: -1.44919262920883 } },
  { rotation: { _x: -0.44421700620508897, _y: 0.28832924073443666, _z: 0.13451735411411736, _order: 'XYZ' }, position: { x: 1.345427, y: 3.348967, z: -0.351463 } },
  { rotation: { _x: -0.44623316914894273, _y: 0.36881327762619354, _z: 0.17079345651097388, _order: 'XYZ' }, position: { x: 1.301769, y: 3.301195, z: -0.451288 } },
  { rotation: { _x: -0.22626480113852235, _y: 0.7237479313098969, _z: 0.15127777555496133, _order: 'XYZ' }, position: { x: 1.761653, y: 3.414964, z: 0.051607 } },
];
let currentStateIndex = 0;

function scrollToState(index) {
  console.log(cameraStates[index])
  gsap.to(camera.rotation, {
    x: cameraStates[index].rotation._x,
    y: cameraStates[index].rotation._y,
    z: cameraStates[index].rotation._z,
    duration: 1,
    ease: 'power2.out'
  });

  gsap.to(camera.position, {
    x: cameraStates[index].position.x,
    y: cameraStates[index].position.y,
    z: cameraStates[index].position.z,
    duration: 1,
    ease: 'power2.out'
  });
}



// Initial state
scrollToState(currentStateIndex);

function handleScroll(event) {
  if (event.deltaY > 0) {
    // Scroll down
    currentStateIndex = Math.min(currentStateIndex + 1, cameraStates.length - 1);
  } else {
    // Scroll up
    currentStateIndex = Math.max(currentStateIndex - 1, 0);
  }

  scrollToState(currentStateIndex);
}

// Attach scroll event listener
// window.addEventListener('wheel', handleScroll);

window.addEventListener('resize', () => {
  camera.aspect = window.innerWidth / window.innerHeight;
  camera.updateProjectionMatrix();
  renderer.setSize(window.innerWidth, window.innerHeight);
});



const animate = () => {
  requestAnimationFrame(animate);

  // Update controls for rotation without zooming
  if (controls && controls.update) {
    // Update controls for rotation without zooming
    controls.update();
  }

  const moveSpeed = 0.1;

  if (moveForward) controls.moveForward(moveSpeed);
  if (moveLeft) controls.moveLeft(moveSpeed);
  if (moveBackward) controls.moveBackward(moveSpeed);
  if (moveRight) controls.moveRight(moveSpeed);

  renderer.render(scene, camera);
};

// renderer.setAnimationLoop(animate); // this is the same as requestAnimationFrame(animate). It will call the animate function over and over again on every frame.

animate();